((w, d, $) => {
  
  /*****
   * サイト設定
   */
  w.siteinfo = {
    tagmanager:       'GTM-NCFHDMB',
    viewport:         'width=1200',
    mapApiKeys: {
      'local':          'AIzaSyCTB3MSMYkTjSz6l30k79XflmmL_FcOxkg', //development
      'www.t-bankin.co.jp': 'AIzaSyANAjmkr4JzkUNH0QQP7VppHAE0JwtmW6E' //production
    }
  }

  /*****
   * Promise
   */
  w.load = {
    'header': new $.Deferred,
    'contentFooter': new $.Deferred,
    'footer': new $.Deferred,
    'mapScript': new $.Deferred,
  };

  /*****
   * VIEWPORT上書き
   */
  (function (d, h, m, p) {
    if ($.UA.is_mobile) return;
    var f = d.getElementsByTagName(h)[0],j = d.createElement(m);
    j.name = p[0];j.content = p[1];f.insertBefore(j, null);
  })(document, 'head', 'meta', ['viewport', w.siteinfo.viewport]);

  /*****
   * Google Tag Manager
   */
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer',w.siteinfo.tagmanager);
  
  /*****
   * GoogleMap API keyの判定
   */
  const env_map_key = ((keymap, host) => {
    return (!keymap[host]) ? keymap['local'] : keymap[host]
  })(w.siteinfo.mapApiKeys, location.host)

  /*****
   * デバイスごとのファイル読み込み
   */
  $.require({
    disableCache:false,
    '*':[
      `(js)https://maps.googleapis.com/maps/api/js?key=${env_map_key}&callback=load.mapScript.resolve`,
      '/asset/js/utility.min.js',
      '/asset/js/vendor/jquery.easing.1.3.js'
    ]
  });
 
  /*****
   * コンテンツごとのファイル読み込み
   */
  $.require({
    disableCache:false,
    '*':{
      '/': [
        '/asset/css/home.min.css',
        '/asset/js/vendor/slick.min.js',
        '/asset/js/vendor/jquery.mb.YTPlayer.min.js',
        '/asset/js/home.min.js'
      ],
      '/service': [
        '/asset/css/service.min.css'
      ],
      '/company': [
        '/asset/css/company.min.css'
      ],
      '/recruit': [
        '/asset/css/recruit.min.css',
        '/asset/js/recruit.min.js'
      ],
      '/works': [
        '/asset/css/works.min.css',
        '/asset/js/works.min.js'
      ]
    },
  });

  // DOM Ready
  $(() => {
    
    $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});

    //header.html, footer.html読み込み
    (function(_){
      $.each(_,function(i,__){
        var mes = 'loadhtml error: ';
        $.get(__.loadhtml)
          .done(function(html){
            if(__.$target[__.method](html).length != 0){ __.callback(this,__) }
            else{
              mes+='Not found DOM $(\'%s\').\nCouldn\'t load \'%s\'';
              console.error(mes,__.$target.selector,this.url);
            }
            w.load[__.name].resolve(html)
          }).fail(function(data){ //ajaxの通信に失敗した場合
            mes+='Ajax failed \'%s\'\n%s (%s)';console.error(mes,this.url,data.statusText,data.status);
            w.load[__.name].reject(data)
            return false;
          });
      });
    }([
        {
          name: 'footer',
          loadhtml: '/asset/html/footer.html',
          $target: $('footer #footerNav'),
          method:'replaceWith',
          callback: function(r,_){
            console.info('%c%s%c is loaded','font-weight: bold',r.url,'');
          }
        },
        {
          name: 'contentFooter',
          loadhtml: '/asset/html/contentFooter.html',
          $target: $('footer,#footer.ui-footer'),
          method:'before',
          callback: function(r,_){
            console.info('%c%s%c is loaded','font-weight: bold',r.url,'');
            $('.homeContentslist').insertBefore('#footer');
          }
        },
        {
          name: 'header',
          loadhtml: '/asset/html/header.html',
          $target: $('header'),
          method:'replaceWith',
          callback: function(r,_){
            console.info('%c%s%c is loaded','font-weight: bold',r.url,'')
            // gmenu current
            $('header .p-gnav').currentMenu({mode:1,default:999});
          }
        }
    ]));

    // <body>にクラス付与
    $.bodyClass();

    // svgスプライト読み込み
    $.loadSVG();

    // htmlデータ読み込み
    $('[data-load-html]').loadHtml();

    // ページングテキスト
    $.pagingText({
      prev:'PREV',
      next:'NEXT',
      list:'LIST'
    });

    // プライバシー・会社概要・サイトマップのコンテンツタイトル
    (function(cnt,path){
      var page = path.match(/^\/([^/]+)\.php$/i);
      if(!page || !cnt[page[1]]) return false;
      var $title = $(`<h2>${cnt[page[1]]}</h2>`),
          $container = $('<section class="mainImg"><div class="mainImgInner"></div></section>');
      $title.appendTo($container.find('.mainImgInner'));
      $container.insertAfter('#gmenu');
      $('.moduletitle').remove();
      var bodyClass = $('body').attr('class').replace(/[?|&]ipsw=\d/,'');
      $('body').attr('class',bodyClass);
    }({
      'sitemap':'サイトマップ',
      'privacy':'プライバシーポリシー',
      'profile':'会社概要'
    },location.pathname));

    // スマホフッターカスタム
    (function(_){ if(!$.UA.is_mobile && _.$footer.length == 0) return;
      _.$footer.parent().insertAfter( _.$needle );
    }({
      $footer: $('.detailFooter .pageingBtnBlock'),
      $needle: ( $('.listContents').length )? $('.listContents') : $('.element_detail').parent()
    }));

    // ページトップリンクをスクロール量で表示する
    $.scrollspy({
      trigger:200,
      onBefore: function(pos){
        $('.go-to-top__wrap').addClass('hidden');
      },
      onAfter: function(pos){
        $('.go-to-top__wrap').removeClass('hidden');
      }
    });

    // メニュー追加
    // $.addGlobalNav([
    //   ['<li><a href="/feature/?mode=detail&article=2">事業案内</a></li>',false,2]
    // ])

  });

  $(w).on('load',function(){


  });

})(window, document, jQuery);